<style lang="less" scoped>
	img {
		width: 100%;
		height: 100%;
	}

	.active {
		background-color: #0090f0;
	}

	.right_side {
		position: fixed;
		z-index: 22;
		top: 400px;
		right: 0px;
		width: 70px;
		background-color: #fff;
		border-radius: 10px;
		box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);

		.side_item {
			text-align: center;
			height: 66px;
			line-height: 85px;
			border-bottom: 2px solid #f2f2f2;
			cursor: pointer;

			.icon {
				width: 26px;
				height: 26px;
			}
		}

		.side_item:nth-child(1) {
			border-radius: 10px 10px 0 0;
		}

		.side_item:nth-child(4) {
			border: none;
			border-radius: 0 0 10px 10px;
		}

		.WX {
			position: absolute;
			top: 65px;
			left: -180px;
			width: 190px;
			height: 190px;
			background: url("../../assets/img/side/bg_1_4.png");
			background-size: 100%;
			font-size: 12px;
			color: #666;
			line-height: 1.5;
			text-align: center;

			img {
				width: 130px;
				height: 130px;
				z-index: 5;
				margin-top: 25px;
				margin-left: -10px;
			}

			>div {
				margin-left: -10px;
			}
		}

		.KF {
			position: absolute;
			top: 135px;
			left: -180px;
			width: 190px;
			height: 70px;
			background: url("../../assets/img/side/bg_1_3.png");
			background-size: 100%;
			font-size: 12px;
			color: #666;
			text-align: center;
			line-height: 70px;
			padding-right: 10px;
			box-sizing: border-box;
		}

		.APP {
			top: 200px;
		}
	}
</style>
<template>
	<div class="right_side">
		<!-- qq -->
		<div class="side_item" v-for="(item, index) in sideList" :key="index" :class="{ active: active == index }"
			@mouseenter="active = index" @mouseleave="active = -1" @click="go(index)">
			<img v-if="active == index" class="icon" :src="item.img2" alt="" />
			<img v-else :src="item.img1" class="icon" alt="" />
		</div>
		<div class="WX" v-if="active == 1">
			<img :src="infoList.qr_code" alt="" />
			<div>扫码关注公众号</div>
		</div>
		<div class="KF" v-if="active == 2">客服热线：{{infoList.mobile}}</div>
		<!-- <div class="WX APP" v-if="active == 3">
      <img src="../../assets/img/home/code.png" alt="" />
      <div>任性学习就靠它</div>
    </div> -->
	</div>
</template>
<script>
	import {
		rightSide
	} from "../../assets/js/index";
	export default {
		data() {
			return {
				infoList: {},
				active: -1,
				scrollTop: "",
				sideList: [{
						img1: require("../../assets/img/side/icon_qq.png"),
						img2: require("../../assets/img/side/icon_qq2.png"),
					},
					{
						img1: require("../../assets/img/side/icon_weixin.png"),
						img2: require("../../assets/img/side/icon_weixin2.png"),
					},
					{
						img1: require("../../assets/img/side/icon_kefu.png"),
						img2: require("../../assets/img/side/icon_kefu2.png"),
					},
					// {
					//   img1: require("../../assets/img/side/icon_app.png"),
					//   img2: require("../../assets/img/side/icon_app2.png"),
					// },
					{
						img1: require("../../assets/img/side/icon_top.png"),
						img2: require("../../assets/img/side/icon_top2.png"),
					},
				],
			};
		},
		mounted() {
			this.getList();
			window.addEventListener("scroll", this.handleScroll);
		},
		destroyed() {
			document.removeEventListener("scroll", this.handleScroll);
		},
		methods: {
			getList() {
				rightSide({
					siteId: window.localStorage.getItem("siteId"),
				}).then((res) => {
					// console.log(res);
					if (res.code == 200) {
						this.infoList = res.data
						// this.infoList.email = res.data.email;
						// this.infoList.mobile = res.data.mobile;
						// this.infoList.qr_code = res.data.qr_code;
					}
				});
			},
			handleScroll() {
				const that = this;
				let scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				that.scrollTop = scrollTop;
			},
			go(i) {
				if (i == 0) {
					let href = `http://wpa.qq.com/msgrd?v=3&uin=${this.infoList.email}&site=qq&menu=yes`;
					window.open(href, "_blank");
				}
				if (i == 3) {
					this.goTop();
				}
			},
			// 回到顶部
			goTop() {
				// window.scroll(0,0);
				const that = this;
				let timer = setInterval(() => {
					let ispeed = Math.floor(-that.scrollTop / 10);
					document.documentElement.scrollTop = document.body.scrollTop =
						that.scrollTop + ispeed;
					if (that.scrollTop === 0) {
						clearInterval(timer);
					}
				}, 10);
			},
		},
	};
</script>
